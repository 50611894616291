body {
  font-family: 'Lato';
  color: #505055;
  line-height: 1.6rem;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat';
  font-weight: 500;
  text-transform: uppercase;
}

.btn {
  color: #fff;
  font-size: 14px;
  font-family: 'Montserrat';
  padding: .55rem 2.2rem;
  border-radius: 0;

  &:hover {
    color: #fff;
  }
}

.nav-link {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

/* TODO moze dać do style.php */
a {
  color: #39a1f1;
}

.header h5 {
  font-size: 16px;
}

#nav  {
  padding: 0;
}

#wrapper > .container {
  padding-right: 15px;
  padding-left: 15px;
}


#navbarNav  {

  .menu {

    .active, & > .nav-item:hover {
      border-right: 1px solid #d9dee2;
      border-left: 1px solid #d9dee2;

      > .nav-link {
        background-color: #f7f8fa;
        position: relative;

        &:before  {
          border-bottom: 3px solid;
          position: absolute;
          width: 80%;
          bottom: 30%;
          left: 0;
          right: 0;
          margin: 0 auto;
          content: "";
          transition: all 0.3s;
          display: none;
        }
      }


    }

    .nav-link {
      text-transform: uppercase;
      font-size: 0.80rem;
      font-family: 'Montserrat';
      padding-right: 0.2rem;
      padding-left: 0.2rem;
    }

    > li  {
      height: auto;
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
    }
    > li > a {
      height: 100%;
      align-items: center;
      display: flex;
    }
  }

  .dropdown-menu  {
    padding: 0;
  }

  .dropdown-item {
    padding-left: 0;
    padding-right: 0;
  }
}

.navbar {
  .menu {
   margin-left: auto;
  }

  .links  {
    color: #7d7d7d;
    margin-top: 0.5rem;
    margin-bottom: 0.6rem;

    .fa   {
      padding-right: 10px;
    }

    a   {
      color: inherit;
    }

    p   {
      margin-bottom: 0;
    }

    .list-inline-item:first-child {
      margin-right: 2rem;
    }

  }
}
/*
.dropdown:hover>.dropdown-menu {
  display: block;
}
*/
#langs {
  padding-left: 1rem;
  margin-bottom: 0;
  border-left: 1px solid #cccccc;

  li {
    display: inline-block;
    margin-right: 0.4rem;

  }
  .flag-icon {
    border: 1px solid #cecece;
    line-height: 0.9rem;

    :before {
    }
  }
  .lang-code  {
    display: none;
  }
}

.dropdown .dropdown-menu {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;

  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
}

.dropdown:hover .dropdown-menu {
  max-height: 200px;
  opacity: 1;
}


/**
  Columny
 */
.page-sidebar-column  {

  #content  {
    width: 100%;
    display: inline-block;
    float: left;
  }

  #sidebar-column {
    width: 100%;
    display: inline-block;
  }
}
.sidebar-column-left  {

  #sidebar-column {
    margin-left: 0;
    margin-right: 5%;
  }
}

#main {
  padding: 5rem 0 3rem 0;

  p {
    color: #8e8e90;
    padding-bottom: 0.5rem;
  }

  .content {

    h2 {
      position: relative;
      padding-bottom: 2.5rem;

      &:after {
        position: absolute;
        bottom: 0.9rem;
        left: 0;
        width: 20%;
        height: 0.25rem;
        content: "";
      }
    }
  }
}

.page #main {
  padding-top: 4rem;

  .content h3, .content h4, .content h5, .content h6  {
    margin-bottom: 1.5rem;
    text-transform: none;
  }
}

.bg-gray  {
  background-color: #eef0f3;
}

.section-home-contact-data  {
  font-size: 18px;

  .itp-col {
    padding: 1rem 1rem 0.5rem 1rem;
  }

  a {
    color: inherit;
  }
}


/**
#parallax
 */
#parallax {
  background-position: center;
  color: #fff;
  position: relative;
  margin-top: 1rem;
  overflow: hidden;

  .container {
    padding: 4rem 3rem 3rem 3rem;
    position: relative;
  }

  &:before  {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0.7;
    background-color: #d99777;
  }

  //&:after {
  //  background-color: #fff;
  //  position: absolute;
  //}
// TODO itp - to nie może być z wysokością na sztywno
  .rectangle {
    position: absolute;
    top:0;
    right: 0;
    width: 0; height: 0; border-top: 250px solid; border-left: 180px solid transparent;
    display: none;

    &:after {
      position: absolute;
      background-color: #fff;
      height: 250px;
      width: 40rem;
      top:-250px;
      right: -40rem;
      content: "";
      display: block;
    }
  }
}

/**
footer-form
 */
.section-footer-form  {
  padding: 4rem 0;
  margin: 1rem 0;

  .title {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    position: relative;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      content: "";
      width: 120px;
      height: 2px;
      margin: 0 auto;
    }
  }

  .form-wrap {
    margin-top: 2rem;

    .form-control {
      display: inline-block;
      width: auto;
      font-size: inherit;
      padding-top: 0.72rem;
      padding-bottom: 0.72rem;
      margin-bottom: 0;
      line-height: initial;
      max-height: 45px;
    }

    #captcha  {
      width: auto;
      border: 1px solid #d3d3d2;
      max-height: 3rem;
    }

    #code {width: 100px;}

    .form-control-email {
      width: auto;
    }

    .list-inline-item {
      margin-bottom: 1rem;
      display: block;
    }

  }
  .text-from-cms  {
    font-size: 1.2rem;
    margin-right: 1rem;
  }
  .btn  {
    border-radius: 3px;
    text-transform: uppercase;
    padding: .7rem 2.4rem;
  }
}


.bg-gray  {
  background-color: #eef0f3;
}

.section-home-contact-data  {
  font-size: 18px;

  .itp-col {
    padding: 1rem 1rem 0.5rem 1rem;
  }

  a {
    color: inherit;
  }
}

.parallax-window {
  min-height: 250px;
  background: transparent;
}


/**
sidebar
 */
.sidebar  {
  padding-left: 0;

  .box  {
    list-style: none;
    margin-bottom: 2rem;
  }

  .box-title  {
    margin-bottom: 1rem; // nie wiem do czego to było
    font-size: 1.4rem;
  }
}

#footer {
  background: #f1f1f1;
  margin-top: 0;
  min-height: auto;
  padding-top: 2rem;
  z-index: 0;
  padding-bottom: 2rem;

  display: flex;
  align-items: center;
  align-content: flex-end;

  p   {
    margin-bottom: 0;
    font-size: 0.9rem;
    position: relative;
    z-index: 1;
    display: table;
  }

  a   {
    color: inherit;
  }
}

/**
#cookies
 */
#cookies  {
  background-color: #eef0f3;
  padding: 20px 0 10px 0;
  position: fixed;
  bottom: 0;
  color: #6f7074;
  z-index: 10;

  .closecookies {
    float: right;
    max-width: 202px;
    font-size: 12px;
    white-space: normal;
    padding: .5rem 0.5rem;
    margin-left: 1rem;

  }

  p   {
   font-size: 14px;
    line-height: 1rem;
  }
}

/**
  Facebook
 */
#slide-box-fblike {cursor: pointer; position: fixed; right: -326px; top: 126px; z-index: 15;}
.no-touch #slide-box-fblike {transition: right 0.4s ease-out 0s, z-index 0.01s ease-in 0.4s;}
.no-touch #slide-box-fblike:hover {right: 0; transition: right 0.4s ease-out 0s, z-index 0.01s ease-in 0s; z-index: 15;}
#slide-box-fblike .inside {background: none repeat scroll 0 0 #FFFFFF; border: 2px solid #39579B; height: 250px; padding: 10px; position: relative; width: 326px; z-index: 2;}
#slide-box-fblike .belt {background: url("../images/bt_social.png") repeat scroll 0 0 #3B5999; border-radius: 3px 0 0 3px; color: #FFFFFF; font-family: Verdana; font-size: 1px; font-weight: bold; height: 49px; left: -47px; position: absolute; top: 0; width: 47px; z-index: 0;}
#slide-box-fblike:hover .belt {background-position: 0 -49px;}

/**
.form-wrap
 */
.form-wrap  {

  .form-control {
    margin-bottom: 0.6rem;
    font-size: 0.9rem;
    padding-top: 0.4rem ;
    padding-bottom: 0.4rem ;
  }

  #captcha  {
    //margin-bottom: 0.6rem;
    max-height: 2.37rem;
    border-radius: 0.25rem;
    width: 100%;
  }
}

/**
#slider-wrap-20
 */
.carousel-item-background-image {
  //height: 100vh;
  min-height: 40vh;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.carousel-caption  {
  right: 0;
  width: 45%;
  left: auto;
  padding: 0;

  .carousel-caption-background  {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    padding-right: 4%;
    padding-left: 18%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:after {
      content: '';
      position: absolute;
      width: 210%;
      height: 210%;
      top: 0;
      left: 29%;
      transform-origin: 54% 0;
      transform: rotate(18deg);
      z-index: -1;
      opacity: 0.9;
    }

  }

  &:before  {
    //TODO tu wrócić bo muszę zwracać rgb mam to zrobione w ukladach iziwww sprawdzić jak jest tam.
    //position: absolute;
    //top: 0;
    //right: 0;
    //width: 40%;
    //content: "";
    //height: 100%;
    //background-color: #0a001f;


  }

}

.layer-mask {
  //background-color: rgba(0,0,0,0.5);
  //width: 100%;
  //height: 100%;
  //position: absolute;
  //z-index: -1;
}

.carousel-control {
  background-color: rgba(255,255,255,0.2);
  padding: 6px 18px;
}
.carousel .desc-wrap  {
  color: #fff;

  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-content: flex-end;
  height: 100%;
  min-height: 100%;
  bottom: auto;
  display: none;

  text-align: right;

  h3 {
    font-size: 2rem;
    font-weight: 600;
  }

  .desc {
    display: none;
  }
}

.carousel-indicators {
  justify-content: flex-end;
  margin-left: auto;
  margin-right: auto;
  padding-right: 0;

  &.active {
    background: none;
  }

  li {
    width: auto;
    height: auto;
    border: 1px solid #fff;
    background: none;
    border-radius: 20px;

    span {
      background-color: #fff;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      display: block;
      margin: 1px;

    }
  }
}

.section-box-footer {
  z-index: 1;
  position: relative;
}

.box-footer-wrap {
  padding: 1rem 0;
  height: auto;

  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 1100%; height: 1100%;
    top: 20px;
    left: -222%;
    //background: rgba(255,255,255,.8);
    transform-origin: 54% 0;
    transform: rotate(60deg);
    z-index: -1;
  }
}

.box-footer {
  background: url("../images/footer_map.png") no-repeat center right;
  color: #aabdce;
  padding: 3rem 0 1rem 0;
  font-size: 0.85rem;



  .box  {
    margin-bottom: 1rem;
  }

  .box-title  {
    color: #fff;
    margin-bottom: 2rem;
  }


  .menu {
    font-size: 0.8rem;

    .dropdown-menu  {
      display: none !important;
    }

    .dropdown-toggle::after {
      display: none;
    }

    .nav-item {
      border-bottom: 1px dashed #5984a8;

      &:last-child {
        border-bottom: none;
      }


    }

    .nav-link {
      padding: 0.35rem 1rem;
      text-transform: uppercase;
      color: #b9b9b9;
      font-weight: bold;

      &:before {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        padding-right: 10px;
        font-size: 0.8rem;
        padding-bottom: 2px;
        vertical-align: middle;
        font-family: "FontAwesome"; content: "\f105";
      }

      &:hover {
        color: #f3f3f3;
      }
    }


  }

  .fa {
    width: 40px;
    height: 40px;
    color: #fff;
    margin-right: 10px;
    border-radius: 3px;
    font-size: 1.6rem;
    //padding-top: 8px;
    opacity: 0.6;
    float: left;

    &:hover  {
      opacity: 0.7;
    }
  }
  .fa-map-marker  {
    padding-top: 8px;
  }
  .fa-facebook  {
    background-color: #1c6db1;
  }
  .fa-twitter  {
    background-color: #39a1f1;
  }
  .fa-youtube  {
    background-color: #9c9c9e;
  }

}

/**
Page
 */
#top-section  {
  background-color: #f7f8fa;

  .top-section-wrap {
    min-height: 150px;
    display: flex;
    align-items: center;
    border-top: 1px solid #d9dee2;
    border-bottom: 1px solid #d9dee2;

    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      width: 1100%; height: 1100%;
      top: 20px;
      right: -465%;
      transform-origin: 54% 0;
      transform: rotate(-60deg);
      z-index: 0;
      display: none;
    }
  }

  .titlewrap  {
    position: relative;
    z-index: 10;
    position: relative;
    padding-left: 2rem;
    max-width: 100%;

    &:after {
      position: absolute;
      left: 0;
      top: 0;
      width: 0.9rem;
      height: 100%;
      //background-color: #10b3ef;
      content: "";
    }
    h2  {
      font-family: 'Lato';
      margin-bottom: 0;
      font-size: 1.2rem;

    }
  }


}

/**
  breadcrumb
 */
.breadcrumb-wrap  {
  color: #fff;
  display: none;

  .breadcrumb {
    background: none;
    margin-bottom: 0;
    border-radius: 0;
    color: #fff;
    position: relative;
    z-index: 1;

    .breadcrumb-item + .breadcrumb-item {
      padding-left: 0.8rem;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      content: "›";
      font-size: 1.5rem;
      line-height: 1.2rem;
      padding-right: 0.8rem;
      color: #fff;
    }

    .breadcrumb-item.active {
      color: #fff;
    }

    .arrow  {display: none}

    .fa   {
      padding: 0 0.5rem 0 0;
    }

    a   {
      color: #fff;
    }
  }
}

/**
uklad1 sidebox
 */
.sidebar-column {

  .box  {
    margin-bottom: 4rem;
  }

  .contact-icon {

    ul {
      list-style: none;
      padding-left: 0;

      li {
        background-color: #f7f8fa;
        font-size: 0.9rem;
        width: 100%;
        vertical-align: middle;
        border-top: 1px solid #dedfe1;
        border-right: 1px solid #dedfe1;

        display: flex;
        align-items: center;

        &:last-child  {
          border-bottom: 1px solid #dedfe1;
        }

        span {
          padding: 10px 16px;
        }

        img {
          padding: 16px 6px;
          float: left;
          min-height: 72px;

        }
      }
    }

  }
}

/**
  uklad2 - 3 boxy
 */
.page-template-3-box  {

  .one-third  {
    text-align: center;
    padding-bottom: 2.5rem;
    overflow: hidden;
    margin-bottom: 2rem;

    .box-title  {
      font-size: 1.5rem;
      margin-bottom: 1.3rem;
      padding-bottom: 1.2rem;
      position: relative;

      &:after {
        position: absolute;
        bottom: 0;
        width: 40%;
        height: 3px;
        left: 0;
        right: 0;
        margin: 0 auto;
        content: "";
      }

    }

    .box-text {
      line-height: 1.4rem;
      padding: 0 2%;
      margin-bottom: 0.5rem;
    }

    .box-more {
      position: absolute;
      bottom: 0;
      width: 96%;

      a {
        text-transform: uppercase;
        font-size: 0.8rem;
      }
    }

    .box-ico-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;
      position: relative;

      &:before {
        background-color: #d7d9d9;
        position: absolute;
        width: 30%;
        top: 50%;
        height: 2px;
        content: "";
        left: -30px;
      }

      &:after {
        background-color: #d7d9d9;
        position: absolute;
        width: 30%;
        top: 50%;
        height: 2px;
        content: "";
        right: -30px;
      }
    }

    .box-ico  {
      position: relative;
      width: 150px;
      height: 150px;
      border-radius: 100px;
      border: 10px solid #eceded;
      transition: all 0.3s ;

      &:before {
        font-family: 'FontAwesome';
        content: '\f0c0';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100px;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
      }
    }

    &:hover  {

      .box-ico {
        border-color: #d7d9d9;
      }
    }

  }

  .one-third-second .box-ico {
    &:before {
      content: '\f091';
    }
  }

  .one-third-last .box-ico {
    &:before {
      content: '\f087';
    }
  }
}

/**
#form-contact-wrap
 */
#form-contact-wrap {

  background-color: #f7f8fa;
  border: 1px solid #d9dee2;
  padding: 2.5rem 2rem;
  font-size: 0.95rem;

  .form-control {
    border-radius: 0;
    font-size: 1.1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .form-group-rodo  {
    padding-top: 2rem;
  }

  .captcha  {
    border: 1px solid #d9dee2;
  }

  .btn  {
    border-radius: 0;
    margin-top: 2rem;
  }
}


/**
  news-listing
 */
.news-listing {

  .item {
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #e5e5e5;
  }

  .item:last-child  {
    border: none;
  }

  .title  {
    font-size: 1.2rem;
    font-family: 'Lato';
    text-transform: none;
    line-height: 1.6rem;
    margin-bottom: 1rem;
  }

  .t_main {
    font-size: 0.9rem;
    line-height: 1.4rem;
    color: #8e8e90;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: .8rem;
    margin-bottom: .8rem;
  }

  .t_category {
    display: none;
  }

  .news-date  {
    color: #8e8e90;
    font-size: 0.9rem;
    float: left;
    margin-top: 0.1rem;
  }

  .news-more {
    padding: .40rem 1.5rem;
    float: right;
    font-weight: normal;
    font-family: 'Lato';
    font-size: 0.95rem;
  }
}

.news-single  {

  .news-date  {
    margin-bottom: 1rem;
  }

  .title  {
    margin-bottom: 1.5rem;
  }

  .thumbwrap  {
    margin-bottom: 1rem;
  }

  .t_category {
    display: none;
  }
}

/**
  gallery-wrap
 */
.gallery-wrap {
  justify-content: center;
  text-align: center;

  .item {
    margin-bottom: 1rem;
    padding-left: 15px;
    padding-right: 15px;


    .img-wrap {
      position: relative;
    }

    .hover-mask {
      background: rgba(0,0,0,0.3);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      overflow:visible;
      transition: all 0.4s ease-in-out;

    }

    a:hover .hover-mask   {
      opacity: 1;
    }
  }
}


/**

  RWD

 */

@media (min-width: 320px) {

}

@media (min-width: 480px) {
  .carousel .desc-wrap  {
    display: flex;
  }

  .carousel-item-background-image {
    min-height: 400px;
  }

}

@media (min-width: 576px) {
  .breadcrumb-wrap {
    display: block;
  }
  #top-section .titlewrap {
    width: 60%;
  }
  #top-section .top-section-wrap::after {
    display: block;
  }

}

@media (min-width: 768px) {
  .carousel-item-background-image {
    min-height: 600px;
  }
  .carousel  {

    .desc-wrap  {

      h3  {
        font-size: 2.5rem;
        width: 100%;
      }
    }
  }

  .section-home-contact-data {
    font-size: 18px;

    .itp-col {
      padding: 4rem 1rem 2.5rem 1rem;
    }
  }
  #top-section .titlewrap  h2  {
    font-size: 1.8rem;
  }

  #navbarNav .menu .active > .nav-link:before,
  #navbarNav .menu > .nav-item:hover > .nav-link:before {
    display: block;
  }

  .slider-content-wrap .desc-wrap .desc {
    display: block;
  }

  #parallax .rectangle {
    display: block;
  }

  .section-footer-form .form-wrap .list-inline-item {
    display: inline-block;
  }

  .box-footer-wrap {
    padding: 3rem 0;
    height: 518px;
  }
  #footer {
    color: #666363;
  }

  #wrapper > .container {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  #navbarNav .menu .nav-link  {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }

  .home #main .content .col-md-6:last-child {
    padding-left: 3rem;
  }
  .page-sidebar-column #content {
    width: 69%;
  }
  .page-sidebar-column #sidebar-column {
    width: 26%;
    margin-left: 5%;
  }

  .page-template-3-box .one-third {
    margin-bottom: 0;
  }

  .section-footer-form .form-wrap .form-control-email {
    width: calc(100% - 739px);
  }

  .section-footer-form .form-wrap .list-inline-item {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .carousel-caption .carousel-caption-background  {
    padding-right: 24%;
  }
  #navbarNav .menu > li {
    height: 112px;
  }

  #footer {
    margin-top: -306px;
    min-height: 306px;
    padding-top: 4rem;
  }
  #footer {
    color: #fff;
  }

}

@media (min-width: 1600px) {

  .carousel-caption .carousel-caption-background  {
    padding-right: 44%;
  }
  #footer {
    color: #666363;
  }
}

/**
RWD - po staremu bo nie wiem jak to trzeba było zrobić
 */
